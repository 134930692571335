<template lang="pug">
.flex.flex-col.w-full.overflow-y-auto
  p.font-bold.text-xl.font-SourceSansPro.text-darkblue {{ $t("notifications.template") }}
  p.text-sm.font-semibold.text-someGrey.font-SourceSansPro.mt-5.leading-4 {{ $t("notifications.textDescription") }}
  .relative
    el-input.mt-5(
      :disabled='isAllowedCreate',
      v-model='notification.notificationtext',
      type='textarea',
      resize='none',
      :rows='7',
      :class='{ "textarea-error": someval }',
      :placeholder='placeHolder'
    )
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='someval') {{ $t("notifications.enterNotifyText") }}
  .font-semibold.text-darkblue.text-base
    p.mt-6.mb-4.font-SourceSansPro {{ $t("notifications.paramTab") }}
    .flex.cols-3.text-xs(style='grid-template-columns: auto 1fr')
      .flex.flex-col.mr-5
        .flex.col-start-1.col-end-2.mb-4.mr-4
          span.text-textmain %LON%
          span.text-someGrey.ml-1 - {{ $t("notifications.longitude") }}
        .flex.col-start-2.col-end-3.mb-4
          span.text-textmain %DRIVER%
          span.text-someGrey.ml-1 − {{ $t("notifications.driver") }}
        .flex.col-start-1.col-end-2.mb-4.mr-4
          span.text-textmain %LAT%
          span.text-someGrey.ml-1 − {{ $t("notifications.latitude") }}
        .flex.col-start-2.col-end-3.mb-4
          span.text-textmain %SPEED%
          span.text-someGrey.ml-1 − {{ $t("notifications.speed") }}
        .flex.col-start-1.col-end-2.mb-4.mr-4
          span.text-textmain %UNIT%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.objectName") }}
        .flex.col-start-1.col-end-3.mb-4.mr-4
          span.text-textmain %ADDRESS%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.adress") }}
        .flex.col-start-2.col-end-3.mb-4
          span.text-textmain %TIME%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.time") }}
        .flex.col-start-2.col-end-3.mb-4
          span.text-textmain  %FILL%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.fill") }}
        .flex.col-start-2.col-end-3.mb-4
          span.text-textmain  %DRAIN%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.drain") }}
      .flex.flex-col
        .flex.col-start-1.col-end-3.mb-4.mr-4
          span.text-textmain %TRIP%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.tripName") }}
        .flex.col-start-2.col-end-3.col-end-3.mb-4
          span.text-textmain %WORK%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.workType") }}
        .flex.col-start-1.col-end-3.mb-4.mr-4
          span.text-textmain %POINT%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.pointName") }}
        .flex.col-start-2.col-end-3.col-end-3.mb-4
          span.text-textmain %TIME_DELTA%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.deviation") }}
        .flex.col-start-1.col-end-2.col-end-3.mb-4
          span.text-textmain %TRIP_EVENT%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t('notifications.text.events_routes') }}
        .flex.col-start-2.col-end-3.mb-4
          span.text-textmain.whitespace-no-wrap %SENSOR%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.sensors") }}
        .flex.col-start-2.col-end-3.mb-4
          span.text-textmain.whitespace-no-wrap %SVALUE%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.sensorValue") }}
        .flex.col-start-2.col-end-3.mb-4
          span.text-textmain.whitespace-no-wrap  %LAST_FUEL%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.final_fuel") }}
        .flex.col-start-2.col-end-3.mb-4
          span.text-textmain.whitespace-no-wrap  %GEOZONE%
          span.text-someGrey.ml-1.whitespace-no-wrap − {{ $t("notifications.geozone_name") }} 

        //- span.text-someGrey.ml-1 - {{ $t("notifications.text.arrival") }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['notification', 'isTextMissing', 'placeHolder'],
  computed: {
    someval: {
      get() {
        return this.isTextMissing
      },
      set(newValue) {
        this.$emit('updateText', newValue)
      }
    },
    ...mapGetters('login', ['role', 'disabledRole']),
    isAllowedCreate() {
      return this.disabledRole && this.role !== 'OPERATOR'
    }
  },
  watch: {
    'notification.notificationtext': function (val) {
      this.someval = val === ''
    }
  }
}
</script>

<style lang="stylus">
.textarea-error {
  .el-textarea__inner {
    border-color: #f56c6c;
  }
}
</style>
